@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Inter:regular,500,700,700&display=swap");

html, body {
    background-color: black;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

body > * {
    flex-shrink: 0;
}

body {
    font-family: "Inter", -apple-system, Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.8;
    font-weight: normal;
    color: whitesmoke;
    margin: 0;
    overflow-x: hidden;
    overflow-y: visible;
    cursor: default;
    user-select: none;
}

a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: #3445b4;
}

a:hover, a:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

button {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

button:hover, button:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    line-height: 1.5;
    font-weight: 400;
    font-family: "Inter", -apple-system, Helvetica, Arial, sans-serif;
    color: #000;
}

.ftco-section {
    padding: 7em 0;
}

.ftco-no-pt {
    padding-top: 0;
}

.ftco-no-pb {
    padding-bottom: 0;
}

.heading-section {
    font-size: 28px;
    color: #000;
}

.heading-section small {
    font-size: 18px;
}

.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.wrapper {
    width: 100%;
}

#sidebar {
    min-width: 270px;
    max-width: 270px;
    background: #0F212E;
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
}

#sidebar .h6 {
    color: #fff;
}

#sidebar.active {
    margin-left: -270px;
}

#sidebar h1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
}

#sidebar h1 .logo {
    color: #fff;
}

#sidebar h1 .logo span {
    font-size: 14px;
    color: #44bef1;
    display: block;
}

#sidebar ul.components {
    padding: 0;
}

#sidebar ul li {
    font-size: 16px;
}

#sidebar ul li > ul {
    margin-left: 10px;
}

#sidebar ul li > ul li {
    font-size: 14px;
}

#sidebar ul li a {
    padding: 10px 0;
    display: block;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li a span {
    color: #44bef1;
}

#sidebar ul li a:hover {
    color: #fff;
}

#sidebar ul li.active > a {
    background: transparent;
    color: #fff;
}

@media (max-width: 991.98px) {
    #sidebar {
        margin-left: -270px;
    }

    #sidebar.active {
        margin-left: 0;
    }
}

#sidebar .custom-menu {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 0;
    margin-right: -20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar .custom-menu {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

#sidebar .custom-menu .btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
}

#sidebar .custom-menu .btn i {
    margin-right: -40px;
    font-size: 14px;
}

#sidebar .custom-menu .btn.btn-primary {
    background: transparent;
    border-color: transparent;
}

#sidebar .custom-menu .btn.btn-primary:after {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #0F212E;
    border-radius: 0.188rem;
}

#sidebar .custom-menu .btn.btn-primary:hover, #sidebar .custom-menu .btn.btn-primary:focus {
    background: transparent !important;
    border-color: transparent !important;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 991.98px) {
    #sidebarCollapse span {
        display: none;
    }
}

#content {
    width: 100%;
    padding: 0;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.btn.btn-primary {
    background: #3445b4;
    border-color: #3445b4;
}

.btn.btn-primary:hover, .btn.btn-primary:focus {
    background: #3445b4 !important;
    border-color: #3445b4 !important;
}

.footer p {
    color: rgba(255, 255, 255, 0.5);
}

.form-control:focus, .form-control:active {
    border-color: #000;
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
}

.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
}

.subscribe-form .form-control {
    background: #3b4ec6;
}

/*Dan*/
.bg-dark-pc {
    background-color: #21242D85;
}

.bg-body-pc {
    /*background-color: #FFFFFF;*/
    background-image: linear-gradient(180deg, #21242D 3%, #FFFFFF 20%);
}

.bg-panel-pc {
    background-color: #213743;
}

.game-table thead tr th {
    background-color: #0F212E;
}

.my_bets thead tr th {
    background-color: #0F212E;
}

.pc-link a {
    color: #E5431E;
}

.my-spinner {
    animation: spinner-border 0.85s linear infinite;
    font-size: x-large;
}

/*history list*/
.history-list-crash {
    overflow: hidden;
    width: 100%;
}

.history-list-crash a:hover {
    color: #FFF !important;
}

.history-list-crash ul {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
}

.history-list-crash li {
    font-size: 0.8rem;
    padding: 1px;
    display: flex;
    flex: 1 1;
    /*align-items: center;*/
    justify-content: center;
    text-align: center;
    margin: 0 1px;
    -webkit-transition: all 120ms ease-in;
    transition: all 120ms ease-in;
    font-weight: 700;

    border-radius: 0.188rem;
    background-image: linear-gradient(206deg, darkgrey, white) !important;
    box-shadow: 1px 1px 1px 1px #21242D;

    height: 17px;
    line-height: 17px;
}

.history-list-crash li:hover {
    /*background: #19692c !important;*/
    transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.btn-send-chat {
    color: #FFF;
    border: 1px solid whitesmoke;
    border-radius: 0px 0.188rem 0.188rem 0px; /*0 10 10 0 Dan*/
}

.btn-send-chat:hover {
    color: #e78f8f;
    border: 1px solid whitesmoke;
    border-radius: 0px 0.188rem 0.188rem 0px; /*0 10 10 0 Dan*/
}

.thumb-xxs {
    height: 24px !important;
    width: 24px !important;
    font-size: 10px;
    font-weight: 700;
}

/* New UI -- Manual Bet */
.btn-amounts {
    border-radius: 0.188rem !important; /*.9rem Dan*/
    background-color: #21242D !important;
    padding: 0px !important;
    width: 45px !important;
    margin: 1px;
    box-shadow: 3px 3px 0.188rem #020b1a80, inset -1px -1px #00000052, inset 1px 1px #fff1cd33 !important;;
    border: 1px solid rgba(0, 0, 0, .9) !important;;
}

#spinner {
    width: 4.8em;
    background-color: transparent;
    border: 0;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0px
}

#spinner:hover {
    box-shadow: none !important;
}

#spinner:disabled {
    color: grey;
}

.btn-betting {
    width: 100%;
    height: 100%;
    border-radius: 0.188rem !important; /*20px Dan*/
    color: whitesmoke;

    text-shadow: 0 1px 0 rgb(0 0 0 / 50%) !important;
    box-shadow: 3px 3px 0.188rem #020b1a80, inset -1px -1px #00000052, inset 1px 1px #fff1cd33 !important;;
    border: 2px solid rgba(0, 0, 0, .9) !important;;
}

.btn-betting-default {
    background: #21242D !important;
}

.btn-betting-wait {
    background: #15161c !important;
}

.btn-betting-cancel {
    background: linear-gradient(130deg, orangered 0%, red 52%) !important;
}

.btn-betting-cashout {
    background: orange !important;
}

.bet-area {
    display: inline-flex;
    align-items: center;
    border-radius: 0.188rem; /*3rem Dan*/
    background-color: #21242D;
    padding-left: 3px;
    padding-right: 3px;
}

.custom-card {
    display: inline-flex;
    align-items: center;
    border-radius: 0.188rem; /*3rem Dan*/
    background: linear-gradient(130deg, #21242D 0%, #F36E21 52%) !important;
    padding: 4px 8px 5px 8px;
}

.custom-input {
    background-color: #060606 !important;
    border: 0;
    border-radius: 0.188rem;
    color: white !important;
    font-size: 1.2rem;
    padding: 0px 7px;
    width: 100%;
    line-height: 1.5;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.custom-label {
    width: 7rem;
}

.custom-button {
    background: linear-gradient(130deg, #21242D 0%, #F36E21 52%) !important;
    border-radius: 0.188rem !important; /*3rem Dan*/
}

.my-table-cell {
    padding: 3px 5px 3px 5px !important;
    font-size: .7rem !important;
}

.my-gradient {
    background: linear-gradient(180deg, #21242D, rgb(0 0 0 / 0%));
    border-radius: 0.188rem; /*.7rem Dan*/
    margin-bottom: 0.1rem;
    padding: 5px 5px;
    margin-right: 3px;
}

.btn-custom {
    color: black;
    background: linear-gradient(130deg, #21242D 0%, #060606 32%) !important;
    border-radius: 0.188rem; /*.2rem Dan*/
    border: 0;
}

.bg-image-custom {
    background: linear-gradient(130deg, #21242D 0%, #F36E21 52%) !important;
}

.bg-image-content {
    background: linear-gradient(130deg, #21242D 0%, #F36E21 52%) !important;
}

.btn-pc-outline {
    color: #F36E21 !important;
    border: 1px solid #F36E21 !important;
}

/* Tabs */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    width: 80%;
    margin: 0 auto .2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #21242D;
    border-radius: 0.188rem;
    padding-left: 0px;
    background-color: #21242D;
}

.react-tabs__tab {
    width: 50%;
    padding: 0.1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.9s;
    border-radius: 0.188rem;
    box-shadow: none;
    margin: 0.07rem;
}

.react-tabs__tab--selected {
    background: #060606;
    box-shadow: none;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:hover {
    background: #060606;
    box-shadow: none;
}

.react-tabs__tab-panel {
    display: none;
    min-height: 211px;
}

.react-tabs__tab-panel--selected {
    display: block;
    /*text-align: center;*/
}

/*End Tabs*/

.custom-panel-lighter {
    background-color: #F36E21 !important;
}

.custom-panel-light {
    background-color: #23313D !important;
}

.custom-panel-dark {
    background-color: #21242D !important;
}

.game-aria {
    /*background-color: #23313D !important;*/
    background-image: url('/assets/images/crashke-canvas-rocket.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position-x: center;
    background-size: contain;
}

.custom-modal-content {
    background-color: #060606;
    color: whitesmoke;
    padding: 1.5px;
    border-radius: 0.188rem;
}

.text-warning-pc {
    color: #F36E21;
}

/*top banner*/
.top-count-down {
    background-image: conic-gradient(from 270deg, #F36E21, #21242D);
    border-radius: 0.3rem;
    color: white;
    width: 20px;
    text-align: center;
    margin: 1px;
}

.help-border {
    border-top: 1px solid #f36e21;
    border-bottom: 1px solid #f36e21;
    border-radius: 0.188rem;
}

.btn-close {
    background-color: #F36E21;
    margin: 0px !important;
}